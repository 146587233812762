<template>
  <div class="edit-account-block">
    <menu-breadcrumb>
      <el-breadcrumb-item :to="{ name: 'Account' }"
        >帳號管理</el-breadcrumb-item
      >
      <el-breadcrumb-item>編輯帳號</el-breadcrumb-item>
    </menu-breadcrumb>

    <div class="container">
      <el-form
        ref="userForm"
        :model="user_form"
        :rules="user_rules"
        label-position="top"
      >
        <el-form-item label="信箱" prop="username">
          <el-input
            v-model="user_form.username"
            show-word-limit
            maxlength="128"
            :disabled="true"
          ></el-input>
        </el-form-item>

        <el-form-item label="密碼" prop="password" ref="password">
          <el-input
            v-model="user_form.password"
            type="password"
            show-password
            placeholder="選填"
          ></el-input>
        </el-form-item>

        <el-form-item
          label="確認密碼"
          prop="confirm"
          ref="confirm"
          v-if="user_form.password.length !== 0"
        >
          <el-input
            v-model="user_form.confirm"
            type="password"
            show-password
          ></el-input>
        </el-form-item>

        <el-form-item label="暱稱" prop="name">
          <el-input
            v-model="user_form.name"
            show-word-limit
            maxlength="64"
          ></el-input>
        </el-form-item>

        <el-form-item label="電話" prop="phone">
          <el-input
            v-model="user_form.phone"
            show-word-limit
            maxlength="64"
          ></el-input>
        </el-form-item>

        <el-form-item label="角色" prop="role">
          <el-select v-model="user_form.role">
            <el-option
              v-for="{ label, value } in roles"
              :key="value"
              :label="label"
              :value="value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="啟用狀態" prop="active">
          <el-radio-group v-model="user_form.active" size="small">
            <el-radio :label="true" :border="true">啟用</el-radio>
            <el-radio :label="false" :border="true">停用</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item>
          <div class="flex-end">
            <el-button type="warning" @click="handleRedirect">回主頁</el-button>
            <el-button type="success" @click="handleSubmit">送出</el-button>
          </div>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { getUser, updateUser, getRoles } from "@/api/user";

export default {
  name: "EditAccount",
  data() {
    const validatePassword = (rule, value, callback) => {
      const passwordLength = this.user_form.password.length;

      if (passwordLength === 0) {
        this.$refs.password.clearValidate();
        if (this.$refs.confirm) {
          this.$refs.confirm.clearValidate();
        }
        callback();
      }

      if (passwordLength < 8 || passwordLength > 64) {
        callback(new Error("長度請介於8-64之間"));
      }

      if (this.user_form.password !== this.user_form.confirm) {
        callback(new Error("請確認密碼"));
      } else {
        this.$refs.password.clearValidate();

        if (this.$refs.confirm) {
          this.$refs.confirm.clearValidate();
        }
        callback();
      }
    };

    return {
      user_form: {
        user_id: "",
        username: "",
        password: "",
        name: "",
        confirm: "",
        role: "admin",
        active: true,
      },
      user_rules: {
        password: [{ validator: validatePassword, trigger: "blur" }],
        confirm: [{ validator: validatePassword, trigger: "blur" }],
        name: [
          { required: true, message: "請輸入暱稱", trigger: "blur" },
          { max: 64, message: "長度過長", trigger: "blur" },
        ],
        phone: [{ max: 64, message: "長度過長", trigger: "blur" }],
        role: [{ required: true, message: "請輸入角色", trigger: "change" }],
        active: [
          { required: true, message: "請輸入角色啟用狀態", trigger: "change" },
        ],
      },
      roles: [],
    };
  },
  created() {
    this.handleGetUser();
    this.handleGetRoles();
  },
  methods: {
    async handleGetUser() {
      try {
        this.user_form = {
          ...this.user_form,
          ...(await getUser(this.$route.params.id)),
        };
      } catch (e) {
        await this.$alert("輸入資料有誤，系統將強制跳轉頁面", "提醒", {
          confirmButtonText: "確定",
          type: "warning",
          callback: async () => {
            this.$message({
              type: "success",
              message: `跳轉成功`,
            });

            await this.handleRedirect();
          },
        });
      }
    },
    async handleGetRoles() {
      this.roles = await getRoles();
    },
    handleSubmit() {
      this.$refs.userForm.validate(async (valid) => {
        if (valid) {
          const cloneForm = { ...this.user_form };

          const fields = ["password", "name", "role", "phone", "active"];

          Object.keys(cloneForm).forEach((key) => {
            if (!fields.includes(key)) {
              delete cloneForm[key];
            }
            if (key === "password" && cloneForm[key].length === 0) {
              delete cloneForm[key];
            }
          });

          await updateUser(this.user_form.user_id, cloneForm);
          this.$message({
            type: "success",
            message: "編輯成功",
          });
          await this.handleRedirect();
        }
      });
    },
    async handleRedirect() {
      await this.$router.push({ name: "Account" });
    },
  },
};
</script>

<style lang="scss" scoped></style>
